<template>
    <div class="mapping-table col-12" :class="{'mapping-table--max-size' : isToggle}">
        <table class="border mb-30">
            <thead>
                <tr>
                    <th colspan="2" class="title">
                        <strong>Available services for <input v-if="isEdit" type="text" style="min-width: 250px;" v-model="mapping.serviceLevelMappingName"><template v-else>{{ mapping.serviceLevelMappingName }}</template>
                            <mercur-button class="btn btn-icon ml-1" :disabled="parentLoading" @click="isEdit = !isEdit"><i class="fas fa-edit"></i></mercur-button>
                            <mercur-button data-test="serviceLevelMappingDeleteTableButton" class="btn btn-icon" :disabled="parentLoading" @click="remove"><i class="fas fa-trash"></i></mercur-button>
                        </strong>
                        <mercur-button data-test="serviceLevelMappingSaveBtn" :disabled="loading || parentLoading" class="btn btn-raised btn-success" @click="saveServiceLevelMapping">
                            <span>Save changes</span>
                        </mercur-button>
                        <mercur-button data-test="serviceLevelMappingToggleButton" @click="isToggle = !isToggle" class="btn btn-icon btn-icon-sm btn-white btn-raised toggler"><i class="fas" :class="isToggle ? 'fa-chevron-up' : 'fa-chevron-down'"></i></mercur-button>
                    </th>
                </tr>
            </thead>
            <tbody v-if="isToggle">
                <tr v-if="!serviceLevels.length">
                    <mercur-spinner />
                </tr>
                <tr data-test="serviceLevelMappingServiceLevel" v-for="serviceLevel in serviceLevels" :key="serviceLevel.serviceLevelId">
                    <td>{{ serviceLevel.serviceLevelName }}</td>
                    <td>
                        <template v-for="label in serviceLevelLabels">
                            <mercur-button
                                data-test="serviceLevelMappingSelectBtn"
                                class="btn mx-0"
                                :class="isSelected(label.serviceLevelLabelName, serviceLevel.serviceLevelId) ? 'btn-purple' : 'btn'"
                                @click="selectLabel(label.serviceLevelLabelName, serviceLevel.serviceLevelId)" :key="label.serviceLevelLabelId">{{ label.serviceLevelLabelName }}</mercur-button>
                        </template>
                    </td>
                </tr>
            </tbody>
            <tbody v-else>
                <tr><td>Click arrow to expand</td></tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import CONFIG from '@root/config'
import { stripProperties } from '@/components/utils/Utils'

export default {
    name: 'ServiceLevelMapping',
    props: {
        mapping: {
            required: true,
        },
        serviceLevels: {
            required: true,
        },
        serviceLevelLabels: {
            required: true,
        },
        parentLoading: {},
    },
    data () {
        return {
            loading: false,
            isToggle: false,
            isEdit: false,
        }
    },
    methods: {
        isSelected (labelName, serviceLevelId) {
            if (!this.ensureInitialized()) {
                return false
            }

            return (this.mapping.serviceLevelMappingTable[labelName] || []).includes(serviceLevelId)
        },
        ensureInitialized () {
            if (!this.mapping) {
                return false
            }
            if (Array.isArray(this.mapping.serviceLevelMappingTable)) {
                this.$set(this.mapping, 'serviceLevelMappingTable', {})
            }
            return true
        },
        selectLabel (labelName, serviceLevelId) {
            if (!this.ensureInitialized()) {
                return
            }

            // PART TO DEAL WITH REMOVE IF THEY CLICK ON SELECTED VALUE
            if (this.isSelected(labelName, serviceLevelId)) {
                const index = this.mapping.serviceLevelMappingTable[labelName].findIndex(e => e === serviceLevelId)
                this.mapping.serviceLevelMappingTable[labelName].splice(index, 1)

                console.log(this.mapping.serviceLevelMappingTable[labelName])
                if (!this.mapping.serviceLevelMappingTable[labelName].length) {
                    this.$delete(this.mapping.serviceLevelMappingTable, labelName)
                }
                return
            }

            // First time usage of label
            if (!this.mapping.serviceLevelMappingTable[labelName]) {
                this.$set(this.mapping.serviceLevelMappingTable, labelName, [serviceLevelId])
                return
            }

            // Append to existing
            this.$set(this.mapping.serviceLevelMappingTable, labelName, [
                ...this.mapping.serviceLevelMappingTable[labelName],
                serviceLevelId,
            ])
        },
        saveServiceLevelMapping () {
            const url = CONFIG.API.ROUTES.PRICING.SERVICE_LEVEL_MAPPING.UPDATE
            this.loading = true
            const payload = stripProperties(this.mapping, ['serviceLevelLabels'])
            this.$api.post(url, payload).then(() => {
                this.$root.$emit('notification:global', {
                    message: 'Service level mapping was updated',
                    type: 'success',
                })
            }).catch((err) => {
                this.$root.$emit('notification:global', {
                    message: 'Something went wrong',
                    type: 'error',
                    errors: err,
                })
            }).finally(() => {
                this.loading = false
            })
        },
        remove () {
            this.$emit('remove', this.mapping)
        },
    },
}
</script>

<style lang="scss" scoped>
    .mapping-table {
        overflow-y: scroll;
        overflow-x: scroll;
        table {
            text-align: left;
            width: 100%;
            border-collapse:separate;
            border-spacing: 0;
            border-radius:6px;
            -moz-border-radius:6px;
            thead th {
                border: none;
                padding: 1rem;
                vertical-align: middle;
            }
            tr:hover {
                background-color: #f1f1f1;
                cursor: pointer;
            }
            td {
                vertical-align: middle;
                padding: 1rem;
                border-top: 1px solid #f1f1f1;
            }
        }
    }

    .title {
        position: relative;
        strong {
            display: inline-block;
            width: calc(100% - 110px);
            vertical-align: middle;
        }

        button {
            margin-right: 0 !important;
            vertical-align: middle;
        }
    }

    .toggler {
        left: 50%;
        transform: translateX(-50%);
        bottom: -15px;
        position: absolute;
        z-index: 2;

        i {
            font-size: 12px;
        }
    }
</style>
