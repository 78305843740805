<template>
    <mercur-card class="fill full-height-layout mx-4 mb-3">
        <grid-header :big-headline="true">
            Service Level Mappings
            <template slot="actions">
                <mercur-button data-test="serviceLevelMappingAddNewButton" :disabled="loading" class="btn btn-primary btn-raised" @click="triggerAddMapping">
                    <span>Create Mapping</span>
                </mercur-button>
                <mercur-button :disabled="loading" class="btn btn-primary btn-raised" @click="triggerAdd">
                    <span>Create Label</span>
                </mercur-button>
                <mercur-button :disabled="loading" class="btn btn-primary btn-raised" @click="triggerDeleteLabel">
                    <span>Delete Label</span>
                </mercur-button>
            </template>
        </grid-header>
        <div class="container-fluid">
            <div class="row" data-test="serviceLevelMappingTables" >
                <mercur-spinner data-test="serviceLevelMappingLoading" v-if="!serviceLevelLabels.length && !serviceLevelMappings && !serviceLevels.length" />
                <template v-else>
                    <service-level-mapping
                        data-test="serviceLevelMappingTables"
                        v-for="mapping in serviceLevelMappings"
                        :service-levels="serviceLevels"
                        :mapping="mapping"
                        :service-level-labels="serviceLevelLabels"
                        :key="mapping.serviceLevelMappingId"
                        :parent-loading="loading"
                        @remove="triggerRemove"
                        class="col-lg-6"
                    ></service-level-mapping>
                </template>
                <div>
                    <mercur-dialog data-test="serviceLevelMappingDeleteLabelModal" :is-open.sync="isOpenDeleteLabelDialog">
                        <h2 class="font-weight-normal mt-0">Select level label to remove</h2>
                        <mercur-select data-test="serviceLevelDeleteLabelModalInput" v-model.trim="deleteForm.serviceLevelLabelId" :class="{'form-invalid': $v.deleteForm.serviceLevelLabelId.$error}">
                            Service level label name
                            <template slot="note">
                                <span class="form-error" v-if="!$v.deleteForm.serviceLevelLabelId.required">Label is required</span>
                                <span class="form-error" v-else-if="!$v.deleteForm.serviceLevelLabelId.unused">Label is in use</span>
                            </template>
                            <option v-for="serviceLevelLabel in serviceLevelLabels" :key="'delete'+serviceLevelLabel.serviceLevelLabelId" :value="serviceLevelLabel.serviceLevelLabelId">{{serviceLevelLabel.serviceLevelLabelName}}</option>
                        </mercur-select>
                        <template slot="footer">
                            <mercur-button data-test="serviceLevelLabelModalDeleteCancelButton" class="btn btn-raised" :disabled="loading" @click="triggerCloseDeleteLabel">Cancel</mercur-button>
                            <mercur-button data-test="serviceLevelLabelModalDeleteButton" class="btn btn-primary btn-raised" @click="deleteLabel" :disabled="loading">
                                Delete service level label
                            </mercur-button>
                        </template>
                    </mercur-dialog>
                </div>
                <div>
                    <mercur-dialog data-test="serviceLevelMappingAddNewLabelModal" :is-open.sync="isOpen">
                        <h2 class="font-weight-normal mt-0">Add service level label</h2>
                        <mercur-input data-test="serviceLevelLabelModalInput" v-model.trim="form.serviceLevelLabelName" :class="{'form-invalid': $v.form.serviceLevelLabelName.$error}">
                            Service level label name
                            <template slot="note">
                                <span class="form-error" v-if="!$v.form.serviceLevelLabelName.required">Name is required</span>
                                <span class="form-error" v-else-if="!$v.form.serviceLevelLabelName.unique">Name needs to be unique</span>
                            </template>
                        </mercur-input>
                        <template slot="footer">
                            <mercur-button data-test="serviceLevelLabelModalCancelButton" class="btn btn-raised" :disabled="loading" @click="triggerClose">Cancel</mercur-button>
                            <mercur-button data-test="serviceLevelLabelModalSaveButton" class="btn btn-primary btn-raised" @click="createLabel" :disabled="loading">
                                Add service level label
                            </mercur-button>
                        </template>
                    </mercur-dialog>
                </div>
                <div>
                    <mercur-dialog data-test="serviceLevelMappingAddNewModal" :is-open.sync="isOpenMappingDialog">
                        <h2 class="font-weight-normal mt-0">Add new service level mapping</h2>
                        <mercur-input data-test="serviceLevelMappingModalNameInput" v-model.trim="mappingForm.serviceLevelMappingName" :class="{'form-invalid': $v.mappingForm.serviceLevelMappingName.$error}">
                            Service level mapping name
                            <template slot="note">
                                <span class="form-error" v-if="!$v.mappingForm.serviceLevelMappingName.required">Name is required</span>
                            </template>
                        </mercur-input>
                        <template slot="footer">
                            <mercur-button class="btn btn-raised" :disabled="loading" @click="triggerCloseMapping">Cancel</mercur-button>
                            <mercur-button data-test="serviceLevelMappingModalSaveButton" class="btn btn-raised btn-primary" @click="submitMapping" :disabled="loading">
                                Add new mapping
                            </mercur-button>
                        </template>
                    </mercur-dialog>
                    <mercur-dialog data-test="serviceLevelMappingDeleteModal" :is-open.sync="isDeleteDialogOpen">
                        <p>Are you sure you want to remove this mapping?</p>
                        <template slot="footer">
                            <mercur-button class="btn btn-raised" :disabled="loading" @click="triggerCloseMapping">Cancel</mercur-button>
                            <mercur-button data-test="serviceLevelMappingDeleteModalConfirmButton" class="btn btn-raised btn-primary" :disabled="loading" @click="remove">Delete</mercur-button>
                        </template>
                    </mercur-dialog>
                </div>
            </div>
        </div>
    </mercur-card>
</template>

<script>
import CONFIG from '@root/config'
import GridHeader from '@/components/GridHeader'
import { required } from 'vuelidate/lib/validators'
import ServiceLevelMapping from '../../components/pricing/ServiceLevelMapping'
const defaultForm = {
    serviceLevelLabelName: '',
}

export default {
    name: 'OptimizedShippingServiceLevels',
    components: { GridHeader, ServiceLevelMapping },
    data () {
        return {
            serviceLevels: [],
            serviceLevelLabels: [],
            serviceLevelMappings: null,
            isOpen: false,
            loading: false,
            form: defaultForm,
            deleteForm: defaultForm,
            isOpenMappingDialog: false,
            mappingForm: {
                serviceLevelMappingName: '',
            },
            draft: {},
            isDeleteDialogOpen: false,
            isOpenDeleteLabelDialog: false,
        }
    },
    validations: {
        form: {
            serviceLevelLabelName: {
                required,
                unique: function (value, vm) {
                    return this.checkIfUnique(vm)
                },
            },
        },
        deleteForm: {
            serviceLevelLabelId: {
                required,
                unused: function (serviceLevelLabelId) {
                    const serviceLevelLabel = this.serviceLevelLabels.find(l => l.serviceLevelLabelId === serviceLevelLabelId)
                    if (serviceLevelLabel) {
                        return !this.serviceLevelMappings.find(m => (m.serviceLevelLabels || []).includes(serviceLevelLabel.serviceLevelLabelName))
                    }
                    return true
                },
            },
        },
        mappingForm: {
            serviceLevelMappingName: {
                required,
            },
        },
    },
    methods: {
        listAllServiceLevels () {
            const url = CONFIG.API.ROUTES.CARRIERS.SERVICELEVELS.OVERVIEW_ALL
            this.$api.post(url, CONFIG.API.REQUEST_DEFAULT).then(({ data }) => {
                this.$set(this, 'serviceLevels', data.data.items)
            })
        },
        listServiceLevelLabels () {
            const url = CONFIG.API.ROUTES.PRICING.SERVICE_LEVEL_LABEL.LIST
            this.$api.post(url, CONFIG.API.REQUEST_DEFAULT).then(({ data }) => {
                this.$set(this, 'serviceLevelLabels', data.data.items)
            })
        },
        listServiceLevelMappings () {
            const url = CONFIG.API.ROUTES.PRICING.SERVICE_LEVEL_MAPPING.LIST
            this.$api.post(url, CONFIG.API.REQUEST_DEFAULT).then(({ data }) => {
                if (data.data.items && data.data.items.length) {
                    this.$set(this, 'serviceLevelMappings', data.data.items)
                }
            })
        },
        triggerAdd () {
            this.isOpen = true
        },
        triggerClose () {
            this.isOpen = false
            this.form = defaultForm
        },
        createLabel () {
            this.$v.$touch()
            if (this.$v.form.$invalid) {
                return
            }

            const url = CONFIG.API.ROUTES.PRICING.SERVICE_LEVEL_LABEL.CREATE
            this.loading = true
            this.$api.post(url, this.deleteForm).then(({ data }) => {
                this.$root.$emit('notification:global', {
                    message: 'Service level label was added',
                    type: 'success',
                })
                this.$set(this, 'serviceLevelLabels', [
                    ...this.serviceLevelLabels,
                    data.data,
                ])
                this.triggerClose()
            }).catch((err) => {
                this.$root.$emit('notification:global', {
                    message: 'Something went wrong',
                    type: 'error',
                    errors: err,
                })
            }).finally(() => {
                this.loading = false
            })
        },
        deleteLabel () {
            this.$v.$touch()
            if (this.$v.deleteForm.$invalid) {
                return
            }

            const url = CONFIG.API.ROUTES.PRICING.SERVICE_LEVEL_LABEL.DELETE
            this.loading = true
            this.$api.post(url, this.deleteForm).then(({ data }) => {
                this.$root.$emit('notification:global', {
                    message: 'Service level label was deleted',
                    type: 'success',
                })
                this.$set(this, 'serviceLevelLabels', [
                    ...this.serviceLevelLabels,
                    data.data,
                ])
                this.triggerClose()
            }).catch((err) => {
                this.$root.$emit('notification:global', {
                    message: 'Something went wrong',
                    type: 'error',
                    errors: err,
                })
            }).finally(() => {
                this.loading = false
            })
        },
        checkIfUnique (vm) {
            const previousNames = this.serviceLevelLabels.map(e => e.serviceLevelLabelName)
            return !previousNames.includes(vm.serviceLevelLabelName)
        },
        triggerDeleteLabel () {
            this.isOpenDeleteLabelDialog = true
        },
        triggerCloseDeleteLabel () {
            this.isOpenDeleteLabelDialog = false
            this.deleteForm = defaultForm
        },

        triggerAddMapping () {
            this.isOpenMappingDialog = true
        },
        triggerCloseMapping () {
            this.isOpenMappingDialog = false
            this.isDeleteDialogOpen = false
            this.mappingForm.serviceLevelMappingName = ''
        },
        submitMapping () {
            this.$v.$touch()
            if (this.$v.mappingForm.$invalid) {
                return
            }

            let url = CONFIG.API.ROUTES.PRICING.SERVICE_LEVEL_MAPPING.CREATE

            this.loading = true
            let payload = this.mappingForm
            payload.serviceLevelMappingTable = []

            // this is auto generated on the backend
            delete payload.serviceLevelLabels

            this.$api.post(url, payload).then(({ data }) => {
                this.$root.$emit('notification:global', {
                    message: 'Mapping was added',
                    type: 'success',
                })
                this.serviceLevelMappings.push(data.data)
                this.triggerCloseMapping()
            }).catch((err) => {
                this.$root.$emit('notification:global', {
                    message: 'Something went wrong',
                    type: 'error',
                    errors: err,
                })
            }).finally(() => {
                this.loading = false
            })
        },
        triggerRemove (data) {
            this.draft = JSON.parse(JSON.stringify(data))
            this.isDeleteDialogOpen = true
        },
        remove () {
            let url = CONFIG.API.ROUTES.PRICING.SERVICE_LEVEL_MAPPING.DELETE.replace('{serviceLevelMappingId}', this.draft.serviceLevelMappingId)
            this.loading = true
            this.$api.delete(url).then(({ data }) => {
                this.$root.$emit('notification:global', {
                    message: 'Mapping was deleted',
                    type: 'success',
                })
                const index = this.serviceLevelMappings.findIndex(e => e.serviceLevelMappingId === this.draft.serviceLevelMappingId)
                this.serviceLevelMappings.splice(index, 1)
                this.triggerCloseMapping()
            }).catch((err) => {
                this.$root.$emit('notification:global', {
                    message: (err.data && err.data.exceptions && err.data.exceptions[0]) ? err.data.exceptions[0] : 'Something went wrong',
                    type: 'error',
                    errors: err,
                })
            }).finally(() => {
                this.loading = false
            })
        },
    },
    created () {
        this.listAllServiceLevels()
        this.listServiceLevelLabels()
        this.listServiceLevelMappings()
    },
}
</script>

<style lang="scss" scoped>
    .service-level-button {
        margin-left: 56%;
    }
</style>
